@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Illustration Styles */
.uni-circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #0a84ff;
  position: absolute;
}

.uni-circle.large {
  width: 200px;
  height: 200px;
  top: 10%;
  left: 15%;
}

.uni-square {
  width: 100px;
  height: 100px;
  background-color: #141414;
  position: absolute;
}

.uni-square.tilt {
  transform: rotate(45deg);
  top: 30%;
  right: 10%;
}

.uni-line {
  width: 5px;
  height: 150px;
  background-color: #0a84ff;
  position: absolute;
  top: 60%;
  left: 20%;
}
